import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatIconAddCircle } from "blocks-react/bedrock/components/Icons/AddCircle/AddCircle";
import { CatIconEditBox } from "blocks-react/bedrock/components/Icons/EditBox/EditBox";
import { CatIconMinusCircle } from "blocks-react/bedrock/components/Icons/MinusCircle/MinusCircle";
import { CatInputField } from "blocks-react/bedrock/components/InputField/InputField";
import React, { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { CaasRoute } from "../../routes";
import { useUpdateProjectAssetDetails } from "../../services/query/updates-project-asset-details.query";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import { Variant } from "../GlobalNotification/GlobalNotification";
import AssetDetailsModal from "./AssetDetailsModal";
const CreateProjectAssetPortfolio = ({ activeStep, setActiveStepHandler }) => {
    const [isModalActive, setIsModalActive] = useState(false);
    const [currentAssetIndex, setCurrentAssetIndex] = useState();
    const [isFieldValueEdit, setIsFieldValueEdit] = useState(false);
    const [editAssetInfo, setEditAssetInfo] = useState({
        assetMetadata: {},
        assetName: "",
        assetSerialNumber: "",
        assetSourceType: ""
    });
    const { setGlobalNotification, userPartyNumber } = useGlobalStore((state) => ({
        setGlobalNotification: state.setGlobalNotification,
        userPartyNumber: state.userPartyNumber
    }));
    const { projectId } = useCreateNewProjectStore((state) => ({
        projectId: state.projectId
    }));
    const navigate = useNavigate();
    const AssetSchema = z.object({
        assetMetadata: z.object({
            productFamily: z.object({ code: z.string().trim().min(1, "product family code is required") })
        }),
        assetName: z.string().trim().min(2, "Asset name is required"),
        assetSerialNumber: z.string().trim().min(2, "Serial number is required"),
        assetSourceType: z.string().trim().min(1, "Select any one")
    });
    const AssetListSchema = z.object({
        assetDetails: z.array(AssetSchema)
    });
    const { control, formState: { errors, isDirty, isValid }, getValues, handleSubmit, trigger } = useForm({
        defaultValues: {
            assetDetails: []
        },
        mode: "onBlur",
        resolver: zodResolver(AssetListSchema)
    });
    const { append, fields, remove, update } = useFieldArray({
        control,
        name: "assetDetails"
    });
    const { mutate } = useUpdateProjectAssetDetails(userPartyNumber, projectId, "create");
    const onSubmit = (formData) => {
        mutate(formData, {
            onError: () => {
                setGlobalNotification({
                    message: "Error. Failed to add assets. Please try later.",
                    showNotification: true,
                    type: Variant.error
                });
                navigate(CaasRoute.Home);
            },
            onSuccess: () => {
                setGlobalNotification({
                    message: "Success. Assets have been added successfully",
                    showNotification: true,
                    type: Variant.success
                });
                navigate(CaasRoute.Home);
            }
        });
    };
    const onCloseModal = () => {
        setIsFieldValueEdit(false);
        setIsModalActive(false);
    };
    const appendFieldValue = (assetInfo) => {
        setIsFieldValueEdit(false);
        append(assetInfo);
        setIsModalActive(false);
    };
    const updateFieldValue = (assetInfo) => {
        setIsFieldValueEdit(false);
        setIsModalActive(false);
        update(currentAssetIndex, assetInfo);
    };
    return (_jsx("div", { children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: "px-7 mt-2.5", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(CatHeading, { variant: "title-sm", children: "Asset Portfolio" }), _jsx(CatHeading, { variant: "label-sm", children: "Add the assets (machine & ESS) you employ and the charging schedule you use to manage the electric fleet." })] }), _jsxs("div", { className: "flex h-96 gap-2 my-3.5", children: [_jsx("div", { className: "w-1/3 max-h-96 overflow-y-auto", children: _jsxs("div", { className: "flex flex-col gap-3 bg-[#F2F2F2] p-3 rounded-lg", children: [_jsx(CatHeading, { variant: "display-sm", children: "Asset list" }), _jsx("div", { className: "flex flex-col gap-3", children: fields.map((field, index) => {
                                                    return (
                                                    // eslint-disable-next-line react/jsx-key
                                                    _jsxs("div", { className: "flex gap-3", children: [_jsx("div", { className: "grow", children: _jsx(Controller, { control: control, name: `assetDetails.${index}`, render: ({ field: { onBlur, onChange, ref, value } }) => {
                                                                        return (_jsx(CatInputField, { hideLabel: true, onBlChange: onChange, onBlur: onBlur, placeholder: "Add serial #", readonly: true, ref: ref, size: "md", value: value.assetSerialNumber
                                                                                ? value.assetSerialNumber
                                                                                : "", ...("assetDetails" in errors &&
                                                                                errors?.assetDetails?.[index] && {
                                                                                errorNote: errors?.assetDetails?.[index].message,
                                                                                status: "error"
                                                                            }) }));
                                                                    } }, field.id) }), _jsx(CatButton, { hideText: true, onClick: () => {
                                                                    setCurrentAssetIndex(index);
                                                                    setEditAssetInfo({
                                                                        ...getValues(`assetDetails.${index}`)
                                                                    });
                                                                    setIsFieldValueEdit(true);
                                                                    setIsModalActive(true);
                                                                }, variant: "ghost", children: _jsxs(React.Fragment, { children: ["Button", _jsx(CatIconEditBox, { slot: "after" })] }, ".0") }), _jsx(CatButton, { hideText: true, onClick: () => {
                                                                    remove(index);
                                                                }, variant: "ghost", children: _jsxs(React.Fragment, { children: ["Button", _jsx(CatIconMinusCircle, { slot: "after" })] }, ".0") })] }));
                                                }) }), _jsx("div", { className: "flex justify-end mr-3", children: _jsxs(CatButton, { onClick: () => {
                                                        setCurrentAssetIndex(fields.length);
                                                        setIsModalActive(true);
                                                    }, size: "sm", children: ["Add 3rd Party OEM", _jsx(CatIconAddCircle, { slot: "after" })] }) })] }) }), _jsx("div", { children: _jsx(AssetDetailsModal, { defaultAssetDetails: isFieldValueEdit
                                            ? { ...editAssetInfo }
                                            : {
                                                assetMetadata: {
                                                    productFamily: {
                                                        code: "CAT"
                                                    }
                                                },
                                                assetName: "",
                                                assetSerialNumber: "",
                                                assetSourceType: ""
                                            }, heading: "Add 3rd Party OEM Asset", ...(isFieldValueEdit && { onApplyChanges: updateFieldValue }), ...(!isFieldValueEdit && { onApplyChanges: appendFieldValue }), onCloseModal: onCloseModal, showModal: isModalActive, subTitle: "Input the non-CAT OEM details to add to the worksite calculation." }) }), _jsx("div", { className: "w-2/3 gap-3 bg-[#F2F2F2] p-4 rounded-lg", children: _jsx(CatHeading, { variant: "title-sm", children: "Network Diagram" }) })] })] }), _jsxs("div", { className: "flex gap-1 justify-end px-7 my-2", children: [_jsx(CatButton, { onClick: () => {
                                setActiveStepHandler(activeStep - 1);
                            }, size: "sm", variant: "primary", children: "Back" }), _jsx(CatButton, { onClick: () => {
                                navigate(CaasRoute.Home);
                            }, size: "sm", children: "Cancel" }), _jsx(CatButton, { disabled: !isDirty || !isValid, onClick: () => {
                                trigger();
                            }, size: "sm", type: "submit", variant: "primary", children: "Finish" })] })] }) }));
};
export default CreateProjectAssetPortfolio;
